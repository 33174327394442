import Box from '@mui/material/Box'
import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { isFunction } from 'lodash-es'
import { forwardRef } from 'react'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { UploadFileEntityResponse } from '../../../../models/types'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { ImageSize, useCMSFile } from '@/core/hooks/useCMSFile'
import { useIsMainLayout } from '@/core/hooks/useLayoutQueries'

/**
 * For standard page content, with optional fade wrapper or gutters.
 */
export const PageItem = forwardRef(function PageItem(
  {
    backgroundColor = 'default',
    backgroundImage,
    backgroundImagePosition,
    children,
    color,
    contained = true,
    drawer,
    escapeContainer = false,
    fillContent,
    height,
    overflow = 'visible',
    px,
    ...props
  }: CardProps & {
    px?: number
    backgroundColor?: string
    backgroundImage?: UploadFileEntityResponse
    backgroundImagePosition?: string
    color?: string
    contained?: boolean
    drawer?: boolean
    escapeContainer?: boolean
    fillContent?: boolean
    height?: number | string
    overflow?: 'hidden' | 'visible'
  },
  ref
) {
  const theme = useTheme()
  let background: string | undefined = backgroundColor
  switch (backgroundColor) {
    case 'default':
      background = theme.palette.background.default
      break
    case 'paper':
      background = theme.palette.background.paper
      break
    case 'primary':
      background = theme.palette.primary.main
      break
    case 'primaryGradient':
      background = `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%), ${theme.palette.primary.main}`
      break
  }
  const { getImagePath } = useCMSFile()
  const isMainLayout = useIsMainLayout()
  const { noLayout } = useFeatures()

  const backgroundImagePath = getImagePath(
    backgroundImage?.data,
    ImageSize.Large
  )
  if (!!backgroundImagePath) {
    background = `${background} url(${backgroundImagePath})`
  }

  const elevation = backgroundColor == 'default' ? 1 : 2

  const card = (
    <Card
      className='page-item'
      elevation={elevation}
      {...props}
      sx={theme => ({
        position: 'relative',
        overflow: overflow,
        color,
        backgroundColor: undefined,
        borderRadius: { xs: 0, md: noLayout ? 0 : 1 },
        borderLeftWidth: { xs: 0, sm: 1 },
        borderRightWidth: { xs: 0, sm: 1 },
        minHeight: fillContent
          ? `calc(100dvh - ${theme.spacing(12)})`
          : undefined,
        height: height,
        width: drawer ? 280 : undefined,
        ...((isFunction(props.sx) ? props.sx(theme) : props.sx) as any)
      })}
    >
      <CardContent
        className='page-item-content'
        sx={theme => ({
          position: 'relative',
          zIndex: 1,
          paddingX: contained
            ? {
                xs: 4,
                sm: px ? px : 4
              }
            : 0,
          paddingY: contained
            ? {
                xs: 5,
                sm: 4
              }
            : 0,
          height: '100%',
          marginX:
            escapeContainer && !isMainLayout
              ? {
                  lg: `calc((-100vw + ${
                    theme.breakpoints.values.lg
                  }px) / 2 + ${theme.spacing(2)} )`
                }
              : 0
        })}
      >
        {children}
      </CardContent>
      <Paper
        square
        className='page-item-background'
        elevation={elevation}
        sx={{
          display: backgroundImagePath ? 'block' : 'none',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 0,
          background: backgroundImagePath ? background : undefined,
          backgroundColor: undefined,
          backgroundPosition: backgroundImagePosition,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderRadius: 0
        }}
      />
    </Card>
  )

  return (
    <Box style={props.style} ref={ref}>
      {card}
    </Box>
  )
})
