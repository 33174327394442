import Grid from '@mui/material/Grid'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'

import { Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { isArray } from 'lodash-es'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import * as Yup from 'yup'
import { useContact } from './useContact'
import { EntityAutocomplete } from '@/components/molecules/input/EntityAutocomplete'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { DiscordTransLink } from '@/components/organisms/site/DiscordTransLink'
import {
  LabeledField,
  LabeledFieldHeader
} from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { TranslationNamespace } from '@/core/translation'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useUserDocument } from '@/entity/user/useUserDocument'
import { useApiError } from '@/core/hooks/useApiError'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

interface FormModel {
  email: string
  player: UserResource
  details: string
}

export const ReportPlayer: React.FC<{
  reportedPlayer?: UserResource
  onBack?: () => void
  onComplete: () => void
}> = ({ reportedPlayer, onBack, onComplete }) => {
  const { t } = useRcTranslation([TranslationNamespace.Contact])
  const { handle } = useApiError()
  const { account } = useUserAccount()
  const { sendContact } = useContact()

  const [query, setQuery] = useState<string>(reportedPlayer?.name || '')
  const [debouncedQuery] = useDebounce(query, 300)
  const {
    users,
    query: { isValidating, size, setSize }
  } = useUserDocument({
    request: {
      q: debouncedQuery
    }
  })

  const onSubmit = async ({ email, details, player }: FormModel) => {
    try {
      await sendContact({
        email,
        details,
        contactType: 'Player Report',
        reportedPlayer: player
      })
      onComplete()
    } catch (error) {
      await handle(error)
    }
    return Promise.resolve()
  }

  const validation = Yup.object<FormModel>({
    email: Yup.string().email(),
    player: Yup.object().required(t('contact:general.name-required')),
    details: Yup.string().required(t('contact:body.body-required'))
  })

  return (
    <Formik
      initialValues={
        { email: '', player: reportedPlayer, details: '' } as FormModel
      }
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form id='report-player-form'>
          <Grid container direction='column' spacing={4}>
            {account ? null : (
              <Grid item>
                <LabeledField
                  component={TextField}
                  label={<RcTrans i18nKey='contact:email.label' />}
                  name='email'
                  variant='outlined'
                  fullWidth
                />
              </Grid>
            )}
            <Grid item>
              <LabeledFieldHeader label={<RcTrans i18nKey='shared.name' />} />
              <EntityAutocomplete
                isValidating={isValidating}
                options={users}
                selected={values.player?.id ? [values.player?.id] : []}
                placeholder={t('user-search-placeholder')}
                variant='circular'
                onSearch={q => setQuery(q)}
                onLoadOptions={() => setSize(size + 1)}
                onChange={async updated => {
                  const player = isArray(updated) ? undefined : updated
                  setFieldValue('player', player)
                }}
              />
            </Grid>
            <Grid item>
              <LabeledField
                component={TextField}
                multiline
                rows={4}
                max={255}
                label={<RcTrans i18nKey='contact:general.details-label' />}
                name='details'
                variant='outlined'
                fullWidth
              />
            </Grid>

            <ModalConfiguration
              title={<RcTrans i18nKey='shared.report-player' />}
              subtitle={
                <>
                  <RcTrans i18nKey='contact:report.description' />{' '}
                  <DiscordTransLink />
                </>
              }
              topAction={onBack}
            >
              <RcButton
                fullWidth
                type='submit'
                disabled={isSubmitting}
                form='report-player-form'
                variant='contained'
              >
                <RcTrans i18nKey='contact:general.submit-report-button' />
              </RcButton>
            </ModalConfiguration>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
