import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import { useCallback } from 'react'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useNavigation } from '@/core/hooks/useNavigation'
import { RootRoute } from '@/core/route-keys'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useMyContactAccounts } from '@/entity/user/useMyContactAccounts'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useFeatures } from '@/components/providers/site/FeatureProvider'

export const useContact = () => {
  const { organization } = useOrganization()
  const { account } = useUserAccount()
  const { user } = useFirebase()
  const { handles } = useMyContactAccounts()
  const { getLinkUrl } = useNavigation()
  const { cfgContactTo } = useFeatures()

  const userEmail = handles.find(it => it.network === NetworkKind.EMAIL)?.handle
  const userDiscord = handles.find(it => it.network === NetworkKind.DISCORD)
    ?.handle

  const sendContact = useCallback(
    async (cfg: {
      details: string
      contactType: string
      email?: string
      reportedPlayer?: UserResource
    }) => {
      const content = cfgContactTo
        ? {
            to: cfgContactTo,
            from:
              cfg.email || userEmail || user?.email || 'support@rallycry.gg',
            subject: `Support Request: ${
              account?.name || 'Unauthenticated User'
            } - ${organization?.name}`,
            html: `
                Source: ${window.document.title}<br/>
                Location: ${window.location.href}<br/>
                User: ${account?.name || 'Unauthenticated User'}<br/>
                User Profile: <a href="${getLinkUrl({
                  root: RootRoute.User,
                  rootId: account?.id
                })}">Link</a><br/>
                Email: ${
                  cfg.email || userEmail || user?.email || 'unknown email'
                }<br/>
                Discord: ${userDiscord || 'discord not linked'}<br/>
                Contact Type: ${cfg.contactType}<br/>
                Reported Player: ${
                  cfg.reportedPlayer
                    ? `<a href="${getLinkUrl({
                        root: RootRoute.User,
                        rootId:
                          cfg.reportedPlayer?.id || cfg.reportedPlayer?.name
                      })}">Link</a>`
                    : 'none'
                }<br/>
                Details:<br/>
                ${cfg.details}
            `
          }
        : ({
            properties: {
              Name: {
                title: [
                  {
                    text: {
                      content: `${account?.name || 'Unauthenticated User'}`
                    }
                  }
                ]
              },
              Org: {
                select: {
                  name: organization?.name
                }
              },
              'Source Link': {
                url: window.location.href
              },
              Source: {
                select: {
                  name: window.document.title
                }
              },
              UserId: {
                number: account?.id || 0
              },
              'User Profile Link': {
                url: getLinkUrl({
                  root: RootRoute.User,
                  rootId: account?.id
                })
              },
              Email: {
                email: cfg.email || userEmail || user?.email || 'unknown email'
              },
              Discord: {
                rich_text: [
                  {
                    type: 'text',
                    text: {
                      content: userDiscord || 'discord not linked'
                    }
                  }
                ]
              },
              'Contact Type': {
                select: {
                  name: cfg.contactType
                }
              },
              Status: {
                select: {
                  name: 'Open'
                }
              }
            },
            children: [
              {
                object: 'block',
                type: 'heading_2',
                heading_2: {
                  rich_text: [{ type: 'text', text: { content: cfg.details } }]
                }
              }
            ]
          } as any)

      if (cfg.reportedPlayer) {
        content.properties['Reported Player Link'] = {
          url: getLinkUrl({
            root: RootRoute.User,
            rootId: cfg.reportedPlayer?.id || cfg.reportedPlayer?.name
          })
        }
      }

      await fetch('/api/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ content })
      })
    },
    [
      organization,
      account,
      getLinkUrl,
      userEmail,
      user?.email,
      userDiscord,
      cfgContactTo
    ]
  )

  return { sendContact }
}
