import { CardActionAreaProps } from '@mui/material/CardActionArea'
import Stack from '@mui/material/Stack'
import React, { PropsWithChildren } from 'react'
import { RcButton } from './RcButton'

export interface RcPaperButtonProps extends CardActionAreaProps {
  onClick?: () => void
  disabled?: boolean
  selected?: boolean
}

export const RcPaperButton: React.FC<PropsWithChildren<RcPaperButtonProps>> = ({
  children,
  onClick,
  disabled,
  selected
}) => {
  const canActivate = !!onClick && !disabled

  return (
    <RcButton
      variant={selected ? 'contained' : 'outlined'}
      onClick={onClick}
      disabled={!canActivate}
      fullWidth
      sx={{
        padding: 3,
        height: '100%'
      }}
    >
      <Stack direction='column' spacing={2} width='100%'>
        {children}
      </Stack>
    </RcButton>
  )
}
