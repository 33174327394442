import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { ChipProps } from '@mui/material/Chip'
import { Form, Formik, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import { filter, some, uniq } from 'lodash-es'

import * as Yup from 'yup'
import { RcButton } from '../interactive/RcButton'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { RcTrans } from '@/components/atoms/RcTrans'

export const CHIPINATOR_DELIMITER = '|||'
export const ChipinatorDisplay = ({
  value,
  onClick,
  onDelete,
  preChips,
  postChips
}: {
  value?: string
  onClick?: (value: string) => Promise<void>
  onDelete?: (value: string) => Promise<void>
  preChips?: ChipProps[]
  postChips?: ChipProps[]
}) => {
  return (
    <Grid container direction='row' spacing={1}>
      {preChips?.map((it, idx) =>
        it.label ? (
          <Grid key={idx} item>
            <Chip color='primary' {...it} />
          </Grid>
        ) : null
      )}

      {some(value)
        ? value?.split(CHIPINATOR_DELIMITER).map(it => (
            <Grid item key={it}>
              <Chip
                size='small'
                key={it}
                label={it}
                onClick={onClick ? () => onClick(it) : undefined}
                onDelete={onDelete ? () => onDelete(it) : undefined}
                sx={{ cursor: onClick ? 'pointer' : 'default' }}
              />
            </Grid>
          ))
        : null}

      {postChips?.map((it, idx) => (
        <Grid key={idx} item>
          <Chip color='primary' {...it} />
        </Grid>
      ))}
    </Grid>
  )
}

export const ChipinatorEditor = ({
  label,
  description,
  value,
  onUpdate
}: {
  label?: React.ReactNode
  description?: string
  value?: string
  onUpdate: (value: string) => Promise<any>
}) => {
  const handleAdd = async (
    { added }: { added: string },
    helpers: FormikHelpers<any>
  ) => {
    const split = value?.split(CHIPINATOR_DELIMITER).filter(it => !!it) || []
    const concat = uniq([...split, added])
    helpers.resetForm()
    return onUpdate(concat?.join(CHIPINATOR_DELIMITER) || '')
  }

  const handleDelete = async (deleted: string) => {
    const split = value?.split(CHIPINATOR_DELIMITER)
    const filtered = filter(split, it => it !== deleted)
    return onUpdate(filtered?.join(CHIPINATOR_DELIMITER) || '')
  }

  const validation = Yup.object().shape({
    added: Yup.string().required()
  })

  return (
    <Formik
      initialValues={{ added: '' }}
      validationSchema={validation}
      onSubmit={handleAdd}
    >
      {({ isSubmitting, submitForm }) => (
        <Form id='chipinator-form'>
          <Stack direction='column'>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Box flexGrow={1}>
                <LabeledField
                  label={label}
                  description={description}
                  component={TextField}
                  name='added'
                  helperText=' '
                  fullWidth
                />
              </Box>
              <Box sx={{ pt: 3 }}>
                <RcButton
                  onClick={submitForm}
                  form='chipinator-form'
                  disabled={isSubmitting}
                >
                  <RcTrans i18nKey='shared.add' />
                </RcButton>
              </Box>
            </Stack>
            <ChipinatorDisplay value={value} onDelete={handleDelete} />
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
