import Box from '@mui/material/Box'
import { isFunction, isUndefined } from 'lodash-es'
import React, { useCallback, useState } from 'react'
import { ModalCard, ModalCardProps } from './ModalCard'
import { ModalConfiguration } from './ModalConfiguration'
import { ModalProvider } from './ModalProvider'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'

export interface ModalContentProps {
  handleClose: () => any
}

export const ModalTrigger = ({
  title,
  subtitle,
  activation,
  children,
  onOpen,
  onClose,
  onExpand,
  modalProps,
  open: controlledOpen,
  inline
}: {
  inline?: boolean
  title?: React.ReactNode
  subtitle?: React.ReactNode
  activation?: (handleOpen: () => void) => React.ReactNode
  children?: ((props: ModalContentProps) => React.ReactNode) | React.ReactNode
  onOpen?: () => any
  onClose?: (res?: any) => any
  onExpand?: () => any
  modalProps?: Partial<ModalCardProps>
  open?: boolean
}) => {
  const [open, setOpen] = useState(controlledOpen || false)

  const handleOpen = () => {
    setOpen(true)
    onOpen?.()
  }

  const handleClose = useCallback(
    (res?: any, reason?: any) => {
      setOpen(false)
      modalProps?.onClose?.(res, reason)
      onClose?.(res)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose /* modalProps, */]
  )

  const openState = isUndefined(controlledOpen) ? open : controlledOpen
  return (
    <PreventParentClick inline={inline}>
      <ModalProvider>
        <Box>
          {activation?.(handleOpen)}
          <ModalCard
            open={openState}
            {...modalProps}
            keepMounted
            onClose={handleClose}
            onExpand={onExpand || modalProps?.onExpand}
          >
            {openState || modalProps?.keepMounted ? (
              <>
                <ModalConfiguration title={title} subtitle={subtitle} />
                {isFunction(children) ? (
                  (children as (props: ModalContentProps) => React.ReactNode)({
                    handleClose
                  })
                ) : (
                  <>{children}</>
                )}
              </>
            ) : null}
          </ModalCard>
        </Box>
      </ModalProvider>
    </PreventParentClick>
  )
}
