import { AvatarProps } from '@mui/material/Avatar'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'

interface PageAvatarProps extends AvatarProps {
  large?: boolean
}

export const PageAvatar = ({ large, sx, ...AvatarProps }: PageAvatarProps) => {
  return (
    <RcAvatar
      variant='rounded'
      sx={{
        width: large ? 96 : 80,
        height: large ? 96 : 80,
        ...sx,
        backgroundColor: 'primary.main'
      }}
      {...AvatarProps}
    />
  )
}
