import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import { FeedbackType } from './_ContactFlow'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcPaperButton } from '@/components/molecules/interactive/RcPaperButton'
import { DiscordTransLink } from '@/components/organisms/site/DiscordTransLink'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { TitleParagraph } from '@/components/molecules/text/TitleParagraph'
import { RcTrans } from '@/components/atoms/RcTrans'

export const SelectFeedback: React.FC<{
  onBack?: () => void
  onSelected: (feedbackType: FeedbackType) => void
}> = ({ onBack, onSelected }) => {
  const [selection, setSelection] = useState<FeedbackType | null>(null)

  const handleSelection = (value: FeedbackType) => {
    if (value === selection) {
      setSelection(null)
    } else {
      setSelection(value)
    }
  }

  return (
    <Grid container direction='column' wrap='nowrap' spacing={3}>
      <Grid item>
        <RcPaperButton
          onClick={() => handleSelection('like')}
          selected={selection === 'like'}
        >
          <TitleParagraph title={<RcTrans i18nKey='contact:general.like' />} />
        </RcPaperButton>
      </Grid>

      <Grid item>
        <RcPaperButton
          onClick={() => handleSelection('dislike')}
          selected={selection === 'dislike'}
        >
          <TitleParagraph
            title={<RcTrans i18nKey='contact:general.dislike' />}
          />
        </RcPaperButton>
      </Grid>

      <Grid item>
        <RcPaperButton
          onClick={() => handleSelection('suggestion')}
          selected={selection === 'suggestion'}
        >
          <TitleParagraph
            title={<RcTrans i18nKey='contact:general.suggestion' />}
          />
        </RcPaperButton>
      </Grid>

      <Grid item>
        <RcPaperButton
          onClick={() => handleSelection('bug')}
          selected={selection === 'bug'}
        >
          <TitleParagraph title={<RcTrans i18nKey='contact:general.bug' />} />
        </RcPaperButton>
      </Grid>

      <ModalConfiguration
        title={<RcTrans i18nKey='contact:give-feedback.label' />}
        subtitle={
          <>
            <RcTrans i18nKey='contact:give-feedback.description' />{' '}
            <DiscordTransLink />
          </>
        }
        topAction={onBack}
      >
        <RcButton
          onClick={() => onSelected(selection!)}
          disabled={!selection}
          fullWidth
          variant='contained'
        >
          {selection ? (
            <RcTrans i18nKey='shared.continue' />
          ) : (
            <RcTrans i18nKey='shared.select-option' />
          )}
        </RcButton>
      </ModalConfiguration>
    </Grid>
  )
}
