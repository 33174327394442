'use client'

import { useState, useEffect } from 'react'
import Image from 'next/image'
import { useTheme } from '@mui/styles'
import { isString } from 'lodash-es'
import { toBase64 } from '../../core/toBase64'
type ViewProps = React.ComponentProps<typeof Image>

const fallbackImage = (primary = 'red', secondary = '#00C2FF') => `
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" width="444" height="444" preserveAspectRatio="none" viewBox="0 0 444 444">
    <g mask="url(&quot;#SvgjsMask2927&quot;)" fill="none">
        <rect width="444" height="444" x="0" y="0" fill="url(&quot;#SvgjsLinearGradient2928&quot;)"></rect>
        <path d="M444 0L369.58 0L444 94.88z" fill="rgba(255, 255, 255, .1)"></path>
        <path d="M369.58 0L444 94.88L444 157.79L208.73999999999998 0z" fill="rgba(255, 255, 255, .075)"></path>
        <path d="M208.74 0L444 157.79L444 215.53L205.20000000000002 0z" fill="rgba(255, 255, 255, .05)"></path>
        <path d="M205.20000000000002 0L444 215.53L444 313.79L74.19000000000003 0z" fill="rgba(255, 255, 255, .025)"></path>
        <path d="M0 444L213.74 444L0 227.93z" fill="rgba(0, 0, 0, .1)"></path>
        <path d="M0 227.93L213.74 444L253.42000000000002 444L0 110.33000000000001z" fill="rgba(0, 0, 0, .075)"></path>
        <path d="M0 110.33000000000004L253.42000000000002 444L298.59000000000003 444L0 43.01000000000005z" fill="rgba(0, 0, 0, .05)"></path>
        <path d="M0 43.01000000000005L298.59000000000003 444L321.56000000000006 444L0 38.80000000000005z" fill="rgba(0, 0, 0, .025)"></path>
    </g>
    <defs>
        <mask id="SvgjsMask2927">
            <rect width="444" height="444" fill="#ffffff"></rect>
        </mask>
        <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" gradientUnits="userSpaceOnUse" id="SvgjsLinearGradient2928">
            <stop stop-color="#0e2a47" offset="0"></stop>
            <stop stop-color="rgba(69, 150, 255, 1)" offset="1"></stop>
        </linearGradient>
    </defs>
</svg>
`

// https://solutions-image-fallback.vercel.app/
export const ImageWithFallback = ({
  alt,
  blur = true,
  src,
  ...props
}: ViewProps & { fallback?: string; blur?: boolean }) => {
  const [error, setError] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    setError(false)
  }, [src])

  return (
    <Image
      draggable={false}
      alt={alt}
      onError={() => setError(true)}
      src={
        error || !src
          ? `data:image/svg+xml;base64,${toBase64(
              fallbackImage(
                theme.palette.primary.light,
                theme.palette.primary.dark
              )
            )}`
          : isString(src)
          ? src.trim()
          : src
      }
      sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
      {...props}
    />
  )
}
