'use client'

import Box from '@mui/material/Box'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { forwardRef } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Stack from '@mui/material/Stack'
import { ModalCardFooter } from './ModalCardFooter'
import { ModalCardHeader, ModalCardSubtitleDisplay } from './ModalCardHeader'
import { useModal } from './ModalProvider'
import { calcElevationString } from '@/style/palette'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' {...props} ref={ref} />
})

export interface ModalCardProps extends Omit<DialogProps, 'title'> {
  isBlockedLayout?: boolean
  defaultFooterLabel?: string
  noAnimation?: boolean
  noHeader?: boolean
  noFooter?: boolean
  noPadding?: boolean
  paddingTop?: number
  skipCloseButton?: boolean
  skipFullScreen?: boolean
  skipDirtyCheck?: boolean
  forceDirtyCheck?: boolean
  drawer?: boolean
  title?: React.ReactNode
  subtitle?: React.ReactNode
  headerBackground?: boolean
  onExpand?: () => any
}

export const ModalCard: React.FC<ModalCardProps> = ({
  children,
  isBlockedLayout,
  noAnimation,
  noHeader,
  noFooter,
  noPadding,
  paddingTop,
  skipCloseButton,
  skipFullScreen,
  skipDirtyCheck,
  forceDirtyCheck,
  drawer,
  maxWidth = 'xs',
  onClose,
  onExpand,
  title,
  headerBackground,
  ...rest
}) => {
  const { formDirty, maxWidth: providerMaxWidth } = useModal()
  const isMobile = useIsMobile()
  const showHeader = !noHeader

  const handleClose = () => {
    const clean = formDirty || forceDirtyCheck
    if (
      skipDirtyCheck ||
      !clean ||
      confirm('You have unsaved form data. Are you sure you want to close?')
    ) {
      onClose?.({}, 'backdropClick')
    }
  }

  return (
    <>
      {drawer ? (
        <SwipeableDrawer
          anchor='bottom'
          open={rest.open}
          onClose={() => handleClose()}
          onOpen={() => {}}
          disableSwipeToOpen
          ModalProps={{
            sx: theme => ({ zIndex: theme.zIndex.modal })
          }}
        >
          <Box maxHeight={'80dvh'} sx={{ overflowY: 'auto' }}>
            {children}
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          fullScreen={isMobile && !skipFullScreen}
          fullWidth
          maxWidth={providerMaxWidth || maxWidth}
          onClose={handleClose}
          BackdropProps={{
            sx: {
              backgroundColor: isBlockedLayout ? 'transparent' : 'default'
            }
          }}
          TransitionComponent={noAnimation ? undefined : Transition}
          PaperProps={{
            elevation: 1,
            sx: theme => ({
              overflowY: 'visible',
              border: 1,
              borderColor: theme.palette.divider
            })
          }}
          {...rest}
        >
          <>
            <Box
              sx={theme => ({
                display: 'relative',
                borderTopLeftRadius: { sx: 0, sm: theme.shape.borderRadius },
                borderTopRightRadius: { sx: 0, sm: theme.shape.borderRadius },
                backgroundColor: headerBackground
                  ? theme.palette.primary.main
                  : undefined,
                backgroundImage: headerBackground
                  ? calcElevationString(theme.palette.mode, [2])
                  : undefined
              })}
            >
              {showHeader ? (
                <ModalCardHeader
                  title={title}
                  skipSubtitle
                  skipCloseButton={skipCloseButton}
                  handleClose={onClose ? handleClose : undefined}
                />
              ) : null}

              <ModalCardSubtitleDisplay />
            </Box>
            <DialogContent
              sx={theme => ({
                display: 'flex',
                flexDirection: 'column',
                padding: noPadding ? 0 : 4,
                paddingTop,
                paddingBottom: 0,
                // consistent padding for content when scrollbar appears
                paddingRight: noPadding ? 0 : 4,
                scrollbarGutter: noPadding ? 'auto' : 'stable'
              })}
            >
              <RcSuspense height={'50dvh'}>{children}</RcSuspense>
            </DialogContent>
            {noFooter ? null : <ModalCardFooter />}
            {showHeader ? null : (
              <Box display='relative'>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 15,
                    right: 24
                  }}
                >
                  <Stack spacing={3} direction='row'>
                    {onExpand ? (
                      <RcIconButton
                        IconProps={{ size: 'xs' }}
                        icon={[
                          'fal',
                          'arrow-up-right-and-arrow-down-left-from-center'
                        ]}
                        onClick={onExpand}
                      />
                    ) : null}

                    {!skipCloseButton ? (
                      <RcIconButton
                        icon={['fal', 'times']}
                        onClick={handleClose}
                      />
                    ) : null}
                  </Stack>
                </Box>
              </Box>
            )}
          </>
        </Dialog>
      )}
    </>
  )
}
