import Box from '@mui/material/Box'
import { DialogTitleProps } from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { useModal } from './ModalProvider'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'

export interface ModalCardHeaderProps extends Omit<DialogTitleProps, 'title'> {
  title?: React.ReactNode
  skipSubtitle?: boolean
  skipCloseButton?: boolean
  handleClose?: () => void
}

export const ModalCardHeader: React.FC<ModalCardHeaderProps> = ({
  title,
  skipSubtitle,
  skipCloseButton,
  handleClose
}) => {
  const theme = useTheme()
  const {
    avatarContainer,
    titleContainer,
    subtitleContainer,
    topActionContainer,
    topContentContainer
  } = useModal()

  return (
    <>
      <Box marginX={theme.spacing(5)} marginY={theme.spacing(3)}>
        <div ref={avatarContainer} />
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs={2}>
            <div ref={topActionContainer} />
          </Grid>
          <Grid item container justifyContent='center' xs>
            <Stack direction='column' alignItems={'center'}>
              <Typography variant='h2' textAlign='center'>
                <div
                  ref={titleContainer}
                  style={title ? { display: 'none' } : undefined}
                />
                {title}
              </Typography>
            </Stack>
          </Grid>
          <Grid item container justifyContent='flex-end' xs={2}>
            {handleClose && !skipCloseButton ? (
              <RcIconButton icon={['fal', 'times']} onClick={handleClose} />
            ) : null}
          </Grid>
        </Grid>
      </Box>

      <div ref={topContentContainer} />

      {skipSubtitle ? null : (
        <Box pb={5}>
          <Typography variant='body1' textAlign='center'>
            <span ref={subtitleContainer} />
          </Typography>
        </Box>
      )}
    </>
  )
}

export const ModalCardTitleDisplay = ({
  title
}: {
  title?: React.ReactNode
}) => {
  const { titleContainer, isInModalContext } = useModal()
  if (!isInModalContext) return null
  return (
    <>
      <span
        ref={titleContainer}
        style={{ display: title ? 'none' : undefined }}
      />
      {title}
    </>
  )
}

export const ModalCardSubtitleDisplay = () => {
  const { subtitleContainer, isInModalContext } = useModal()
  if (!isInModalContext) return null
  return (
    <Box px={5}>
      <span ref={subtitleContainer} />
    </Box>
  )
}

export const ModalCardChatActions = () => {
  const { topActionContainer, isInModalContext } = useModal()
  if (!isInModalContext) return null
  return <div ref={topActionContainer} />
}
