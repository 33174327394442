import saveAs from 'file-saver'
import { Maybe, UploadFileEntity } from '../../../models/types'
import { useOrganization } from '../../entity/organization/useOrganization'

export enum ImageSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Thumbnail = 'thumbnail'
}

export const useCMSFile = () => {
  const { storageUrl } = useOrganization()

  const getImagePath = (
    it: Maybe<UploadFileEntity> | undefined,
    size: ImageSize
  ) => {
    if (!it) return undefined
    const attrs = it?.attributes?.formats?.[size] || it?.attributes
    return attrs?.url?.replace(`https://storage.rallycry.gg`, storageUrl)
  }

  const getImageStorageUrl = (input?: Maybe<string>) =>
    input?.replace(`https://storage.rallycry.gg`, storageUrl) || ''

  const download = async (href: string, name: string) => {
    saveAs(getImageStorageUrl(href)!, name)
  }

  return { getImagePath, getImageStorageUrl, download }
}
