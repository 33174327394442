import {
  ContactAccountControllerApi,
  ReadAllContactAccountsByUserRequest
} from '@rallycry/api-suite-typescript/dist/apis/ContactAccountControllerApi'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import {
  UserAccountControllerApi,
  VerificationControllerApi
} from '@rallycry/api-suite-typescript/dist/apis'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useOrganization } from '../organization/useOrganization'
import { useUserAccount } from './useUserAccount'
import { useUserMeta } from './useUserMeta'
import { useController } from '@/core/hooks/useSWRApi'

export const useMyContactAccounts = (
  options?: EntityOptions<ReadAllContactAccountsByUserRequest>
) => {
  const { orgKey } = useOrganization()
  const { ctrl } = useController(ContactAccountControllerApi)
  const { ctrl: accountCtrl } = useController(UserAccountControllerApi)
  const { ctrl: verificationCtrl } = useController(VerificationControllerApi)
  const { account } = useUserAccount()
  const { read: userMeta } = useUserMeta()

  const request = {
    network: Object.values(NetworkKind)
  }
  const entity = useQueryEntity({
    key: 'useUserSelfContactAccounts',
    expand: 'content{_links}',
    paused: !account,
    ...options,
    request: { ...request, ...options?.request },
    metas: [userMeta],
    read: (req, { contactAccounts, expiredCode }) =>
      ctrl({ metas: { contactAccounts, expiredCode } }).readMyContactAccounts({
        userId: account?.id,
        ...req
      }),
    create: (cmd: { network: NetworkKind; handle: string }) =>
      ctrl().saveMyContactAccount({
        network: cmd.network,
        ContactAccountCommand: {
          handle: cmd.handle
        }
      }),
    remove: id => ctrl().removeSelfContactAccount({ id })
  })

  const refresh = async (id: number) => {
    await ctrl().refreshContactAccount({ id })
    await entity.query.mutate()
  }

  const verifyAdditionalEmail = async (email?: string) => {
    await verificationCtrl().createVerification({
      VerificationCreateCodeCommand: { organization: orgKey!, email }
    })
  }

  const updatePrimaryEmail = async (newPrimaryEmailId?: number) => {
    if (!newPrimaryEmailId) throw new Error('newPrimaryEmailId is required')
    await accountCtrl().setMyPrimaryEmail({
      SetPrimaryEmailCommand: { newPrimaryEmailId }
    })
    await entity.query.mutate()
  }

  const primaryEmail = entity.flat.find(
    it => it.primary && it.network === NetworkKind.EMAIL
  )

  return {
    ...entity,
    primaryEmail,
    handles: entity.flat,
    refresh,
    verifyAdditionalEmail,
    updatePrimaryEmail
  }
}
