import Grid from '@mui/material/Grid'
import { Form, Formik } from 'formik'
import { TextField } from 'formik-mui'

import * as Yup from 'yup'
import { useContact } from './useContact'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { DiscordTransLink } from '@/components/organisms/site/DiscordTransLink'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useApiError } from '@/core/hooks/useApiError'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { TranslationNamespace } from '@/core/translation'

class FormModel {
  email = ''
  details = ''
}

export const Other: React.FC<{
  onBack?: () => void
  onComplete: () => void
}> = ({ onBack, onComplete }) => {
  const { handle } = useApiError()
  const { account } = useUserAccount()
  const { sendContact } = useContact()
  const { t } = useRcTranslation([TranslationNamespace.Contact])

  const onSubmit = async ({ email, details }: FormModel) => {
    try {
      await sendContact({ email, details, contactType: 'Other' })
      onComplete()
    } catch (error) {
      await handle(error)
    }
  }

  const validation = Yup.object<FormModel>({
    email: Yup.string().email(),
    details: Yup.string().required(t('contact:body.body-required'))
  })

  return (
    <Formik
      initialValues={new FormModel()}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form id='report-other-form'>
          <Grid container direction='column' spacing={4}>
            {account ? null : (
              <Grid item>
                <LabeledField
                  component={TextField}
                  label={<RcTrans i18nKey='contact:email.label' />}
                  name='email'
                  variant='outlined'
                  fullWidth
                />
              </Grid>
            )}
            <Grid item>
              <LabeledField
                component={TextField}
                multiline
                rows={4}
                max={255}
                label={<RcTrans i18nKey='contact:general.details-label' />}
                name='details'
                variant='outlined'
                fullWidth
              />
            </Grid>

            <ModalConfiguration
              title={<RcTrans i18nKey='contact:other.label' />}
              subtitle={
                <>
                  <RcTrans i18nKey='contact:other.description' />{' '}
                  <DiscordTransLink />
                </>
              }
              topAction={onBack}
            >
              <RcButton
                fullWidth
                type='submit'
                disabled={isSubmitting}
                form='report-other-form'
                variant='contained'
              >
                <RcTrans i18nKey='shared.submit' />
              </RcButton>
            </ModalConfiguration>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
