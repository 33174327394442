import Grid from '@mui/material/Grid'
import { Form, Formik, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import { first } from 'lodash-es'
import { useState } from 'react'
import * as Yup from 'yup'
import { usePage } from '@/components/providers/site/PageProvider'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { tryExtractJsonError } from '@/core/utils'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { useApiError } from '@/core/hooks/useApiError'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

class FormModel {
  description = ''
}

export const SubmitTicket: React.FC<{
  onBack?: () => void
  onComplete: (chatId?: string) => void
}> = ({ onBack, onComplete }) => {
  const { contact } = usePage()
  const [existing, setExisting] = useState<number>()
  const { handle } = useApiError()
  const { t } = useRcTranslation()

  const onSubmit = async (
    { description }: FormModel,
    helpers: FormikHelpers<FormModel>
  ) => {
    try {
      const res = await contact?.action(description)
      onComplete(res?.chatId!)
    } catch (error) {
      const extracted = await tryExtractJsonError(error)

      const firstError = first(extracted.issues)
      if (firstError?.field === 'description') {
        helpers?.setFieldError('description', firstError?.message)
      } else if (firstError?.context?.existing) {
        setExisting(firstError?.context?.existing)
      } else {
        handle(error)
      }
    }

    return Promise.resolve()
  }

  const validation = Yup.object<FormModel>({
    description: Yup.string().required(t('contact:body.body-required'))
  })

  return (
    <Formik
      initialValues={new FormModel()}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ values, errors, isSubmitting }) => (
        <Form id='submit-ticket-form'>
          <Grid container direction='column' spacing={4}>
            <Grid item>
              <LabeledField
                component={TextField}
                label={
                  <RcTrans i18nKey='contact:submit-ticket.description-label' />
                }
                name='description'
                variant='outlined'
                fullWidth
                multiline
                rows={4}
                max={255}
              />
            </Grid>

            <Grid item>
              <RcSuspense height={100}>
                {existing &&
                  contact?.existing(existing, values.description, onComplete)}
              </RcSuspense>
            </Grid>

            <ModalConfiguration
              title={
                <RcTrans
                  i18nKey='contact:submit-ticket.title'
                  tOptions={{
                    name: contact?.entity?.name
                  }}
                />
              }
              subtitle={
                <RcTrans
                  i18nKey='contact:submit-ticket.subtitle'
                  tOptions={{
                    name: contact?.entity?.name
                  }}
                />
              }
              topAction={onBack}
            >
              <RcButton
                fullWidth
                type='submit'
                disabled={isSubmitting}
                form='submit-ticket-form'
                variant='contained'
              >
                <RcTrans i18nKey='shared.submit' />
              </RcButton>
            </ModalConfiguration>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
