import Typography from '@mui/material/Typography'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { ChipinatorDisplay } from '@/components/molecules/text/Chipinator'

/*
 * Avatar generic display
 */
export const SearchAvatar = ({
  resource,
  fallbackImage,
  variant = 'rounded'
}: {
  resource: {
    id?: string
    name?: string
    disambiguatingDescription?: string
    image?: string
  }
  fallbackImage?: string
  variant?: 'square' | 'rounded' | 'circular'
}) => {
  return (
    <AvatarText src={resource.image || fallbackImage} variant={variant}>
      <Typography variant='body0'>{resource.name}</Typography>
      {resource.disambiguatingDescription ? (
        <ChipinatorDisplay value={resource.disambiguatingDescription} />
      ) : null}
    </AvatarText>
  )
}
