'use client'

import React, { PropsWithChildren, use, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { ModalFooter } from './ModalFooter'
import { ModalTitle, ModalTitleProps } from './ModalTitle'
import { ModalTopAction, ModalTopActionProps } from './ModalTopAction'
import { useModal } from './ModalProvider'
import { usePage } from '@/components/providers/site/PageProvider'

export interface ModalConfigurationProps
  extends ModalTopActionProps,
    ModalTitleProps {
  avatarPath?: string
  error?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  direction?: 'row' | 'column'
}

export const ModalConfiguration: React.FC<
  PropsWithChildren<ModalConfigurationProps>
> = ({
  title,
  subtitle,
  topContent,
  error,
  avatarPath,
  topAction,
  topActionProps,
  direction,
  maxWidth,
  children
}) => {
  const ctx = useFormikContext<any>()
  const { isInModalContext, setFormDirty, setMaxWidth } = useModal()
  const { open } = usePage()

  useEffect(() => {
    setFormDirty?.(ctx?.dirty || false)
  }, [setFormDirty, ctx?.dirty])

  useEffect(() => {
    if (maxWidth) {
      setMaxWidth?.(maxWidth)
    }
  }, [setMaxWidth, maxWidth])

  useEffect(() => {
    if (!error) return
    open(undefined, error)
  }, [error, open])

  if (!isInModalContext) {
    return (
      <>
        {title}
        {subtitle}
        {children}
      </>
    )
  }

  return (
    <>
      <ModalTitle
        avatarPath={avatarPath}
        title={title}
        subtitle={subtitle}
        topContent={topContent}
      />

      <ModalTopAction topAction={topAction} topActionProps={topActionProps} />

      <ModalFooter direction={direction}>{children}</ModalFooter>
    </>
  )
}
