import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import React, { useState } from 'react'
import { Complete } from './Complete'
import { GiveFeedback } from './GiveFeedback'
import { Other } from './Other'
import { ReportPlayer } from './ReportPlayer'
import { SelectContact } from './SelectContact'
import { SelectFeedback } from './SelectFeedback'
import { SubmitTicket } from './SubmitTicket'
import { useChat } from '@/components/providers/site/ChatProvider'

export type ContactFlowStep =
  | 'selectContact'
  | 'ticket'
  | 'report'
  | 'selectFeedback'
  | 'giveFeedback'
  | 'other'
  | 'complete'

export type FeedbackType = 'like' | 'dislike' | 'suggestion' | 'bug'

export const ContactFlow: React.FC<{
  initial?: ContactFlowStep
  reportedPlayer?: UserResource
  onComplete: (actionTaken: boolean) => void
}> = ({ reportedPlayer, initial, onComplete }) => {
  const { handleShowChat } = useChat()
  const [state, setState] = useState<{
    chatId?: string
    feedback?: FeedbackType
    step: ContactFlowStep
  }>({
    step: initial || 'selectContact'
  })
  const setStep = (step: ContactFlowStep) => {
    setState(s => ({ ...s, step }))
  }

  const setFeedback = (feedback: FeedbackType) => {
    setState(s => ({ ...s, step: 'giveFeedback', feedback }))
  }

  return (
    <>
      {
        {
          selectContact: <SelectContact onSelected={step => setStep(step)} />,
          report: (
            <ReportPlayer
              reportedPlayer={reportedPlayer}
              onBack={() =>
                initial ? onComplete(false) : setStep('selectContact')
              }
              onComplete={() => setStep('complete')}
            />
          ),
          selectFeedback: (
            <SelectFeedback
              onBack={() =>
                initial ? onComplete(false) : setStep('selectContact')
              }
              onSelected={option => setFeedback(option)}
            />
          ),
          giveFeedback: (
            <GiveFeedback
              feedbackType={state.feedback}
              onBack={() =>
                initial ? onComplete(false) : setStep('selectFeedback')
              }
              onComplete={() => setStep('complete')}
            />
          ),
          ticket: (
            <SubmitTicket
              onBack={() =>
                initial ? onComplete(false) : setStep('selectContact')
              }
              onComplete={chatId => {
                onComplete(false)
                handleShowChat(chatId)
              }}
            />
          ),
          other: (
            <Other
              onBack={() =>
                initial ? onComplete(false) : setStep('selectContact')
              }
              onComplete={() => setStep('complete')}
            />
          ),
          complete: <Complete onComplete={() => onComplete(true)} />
        }[state.step]
      }
    </>
  )
}
