import Box from '@mui/material/Box'
import Portal from '@mui/material/Portal'
import { PageAvatar } from '../page/PageAvatar'
import { useModal } from './ModalProvider'

export interface ModalTitleProps {
  avatarPath?: string
  title?: React.ReactNode
  subtitle?: React.ReactNode
  topContent?: React.ReactNode
}

export const ModalTitle: React.FC<ModalTitleProps> = ({
  avatarPath,
  title,
  subtitle,
  topContent
}) => {
  const {
    avatarContainer,
    titleContainer,
    subtitleContainer,
    topContentContainer
  } = useModal()

  return (
    <>
      {avatarContainer?.current !== null ? (
        <Portal container={avatarContainer?.current}>
          {avatarPath ? (
            <Box display='flex' justifyContent='center' sx={{ mt: -16, mb: 4 }}>
              <PageAvatar src={avatarPath} />
            </Box>
          ) : null}
        </Portal>
      ) : null}
      {titleContainer?.current !== null ? (
        <Portal container={titleContainer?.current}>{title}</Portal>
      ) : null}
      {subtitleContainer?.current !== null ? (
        <Portal container={subtitleContainer?.current}>{subtitle}</Portal>
      ) : null}
      {topContentContainer?.current !== null ? (
        <Portal container={topContentContainer?.current}>{topContent}</Portal>
      ) : null}
    </>
  )
}
