import { AvatarProps } from '@mui/material/Avatar'
import Avatar from '@mui/material/Avatar'
import { forwardRef } from 'react'
import { sizeSx } from './AvatarText'
import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'

export const RcAvatar = forwardRef(function RcAvatar(
  props: AvatarProps & {
    size?: 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl'
  },
  ref: any
) {
  const { src, ...rest } = props
  const baseSx = sizeSx[props.size || 'medium']
  return (
    <Avatar sx={baseSx} {...rest} ref={ref}>
      {src?.startsWith('http') ? (
        <ImageWithFallback
          src={src!}
          alt={props.alt || 'unknown image'}
          width={(props.sx as any)?.width || baseSx?.width}
          height={(props.sx as any)?.height || baseSx?.height}
          draggable={false}
        />
      ) : (
        props.children
      )}
    </Avatar>
  )
})
