import DialogActions from '@mui/material/DialogActions'
import { useTheme } from '@mui/material/styles'

import { useModal } from './ModalProvider'

export interface ModalCardFooterProps {}

export const ModalCardFooter: React.FC<ModalCardFooterProps> = () => {
  const { actionsContainer, isInModalContext } = useModal()
  const theme = useTheme()

  if (!isInModalContext) return null

  return (
    <>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <div
          ref={actionsContainer}
          style={{
            width: '100%',
            height: '100%',
            padding: theme.spacing(2),
            paddingRight: theme.spacing(4)
          }}
        />
      </DialogActions>
    </>
  )
}
