import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Portal from '@mui/material/Portal'
import { useTheme } from '@mui/material/styles'
import { Children } from 'react'
import { useModal } from './ModalProvider'

export const ModalFooter = ({
  children,
  direction
}: {
  children?: React.ReactNode
  direction?: 'row' | 'column'
}) => {
  const { actionsContainer } = useModal()
  const theme = useTheme()

  if (!children) return null

  const arrayed = Children.toArray(children).filter(Boolean)
  const remainder = arrayed.length % 3
  const lastsize =
    arrayed.length === 1 && direction === 'row'
      ? 6
      : remainder === 1
      ? 12
      : remainder === 2
      ? 6
      : 4

  const display = (
    <Grid
      container
      direction={direction || 'column'}
      justifyContent={
        arrayed.length === 1 && direction === 'row'
          ? 'flex-end'
          : 'space-between'
      }
      spacing={5}
    >
      {arrayed.filter(Boolean).map((it, idx) => (
        <Grid
          key={idx}
          item
          xs={arrayed.length - idx <= remainder ? lastsize : 4}
        >
          {it}
        </Grid>
      ))}
    </Grid>
  )

  // show a default footer in place when no container exists to work around
  // weird bug when swapping views that are already cached (landing -> login/register)
  return actionsContainer.current === null ? (
    <Box paddingX={theme.spacing(0)} paddingTop={theme.spacing(8)}>
      {display}
    </Box>
  ) : (
    <Portal container={actionsContainer?.current}>{display}</Portal>
  )
}
