import {
  SearchUsersRequest,
  UserDocumentControllerApi
} from '@rallycry/api-suite-typescript/dist/apis/UserDocumentControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserDocument = (options: EntityOptions<SearchUsersRequest>) => {
  const { ctrl } = useController(UserDocumentControllerApi)

  const entity = useQueryEntity({
    key: 'useUserDocument',
    paused: !options.request,
    ...options,
    read: req => ctrl().searchUsers(req)
  })

  return { ...entity, users: entity.flat }
}
