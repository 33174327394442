import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'

import { RcTrans } from '@/components/atoms/RcTrans'
import { useOrganization } from '@/entity/organization/useOrganization'

export const DiscordTransLink = ({ override }: { override?: string }) => {
  const { profile } = useOrganization()

  const resolvedLink = override || profile?.primaryDiscordInviteUrl

  // skip showing if no discord link
  if (!resolvedLink) return null

  return (
    <RcTrans i18nKey='contact:menu.discord'>
      <Link href={resolvedLink} target='_new' rel='noopener' />
    </RcTrans>
  )
}
