'use client'

import { some, take, uniqBy } from 'lodash-es'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { useFirebase } from './FirebaseProvider'
import { useImpersonation } from './ImpersonationProvider'
import { BaseProviderState, Initial } from '@/core/base-provider-state'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { useIsMdUp } from '@/core/hooks/useMediaQueries'
import { useNavigation } from '@/core/hooks/useNavigation'
import { RootRoute } from '@/core/route-keys'

export const useChat = () => useContext(ChatContext)

const MAX_ACTIVE_CHATS = 4
interface ChatContext extends BaseProviderState {}

const useChatApi = () => {
  const { user } = useFirebase()
  const { navTo } = useNavigation()
  const isMdUp = useIsMdUp()
  const [state] = useState<ChatContext>(Initial)

  // chats to be shown in desktop chat drawer
  const [activeChats, setActiveChats] = useLocalStorage({
    key: `chat-sessions-${user?.uid}`,
    defaultValue: [] as { id: string; focused: boolean }[]
  })

  const handleShowChat = (id?: string) => {
    if (!id) return
    if (isMdUp) {
      setActiveChats(prev =>
        take(
          some(prev, it => it.id === id)
            ? prev?.map(p => ({
                ...p,
                focused: p.id === id
              }))
            : [
                { id, focused: false },
                ...prev.map(it => ({ ...it, focused: false }))
              ],
          MAX_ACTIVE_CHATS
        )
      )

      // timeout focus so transition can fire on first load of chat
      setTimeout(() => {
        setActiveChats(prev => {
          const match = prev.find(it => it.id === id)
          if (match) {
            match.focused = true
          }
          return [...prev]
        })
      }, 0)
    } else {
      navTo({ root: RootRoute.Chat, subRoute: id })
    }
  }

  const handleActivate = (id: string) =>
    setActiveChats(
      prev =>
        prev?.map(p => ({
          ...p,
          focused: p.id === id ? !p.focused : false
        }))
    )

  const handleCloseChat = (id: string) =>
    setActiveChats(prev => [...prev.filter(p => p.id !== id)])

  const { isProfile, impersonation } = useImpersonation()
  const [activity, setActivity] = useLocalStorage<
    { id?: string; lastMessage?: Date; lastActive?: Date; pinned?: boolean }[]
  >({
    key: `chat-activity${isProfile ? impersonation?.id : ''}`,
    defaultValue: []
  })

  const getChatActivity = useCallback(
    (id: string) => {
      return activity?.find(it => it.id === id)
    },
    [activity]
  )

  const updateChatActivity = useCallback(
    (id: string, modified: { lastActive?: Date; lastMessage?: Date }) => {
      setActivity(prev => {
        const existing = prev.find(it => it.id === id)
        const update = existing
          ? {
              ...existing,
              ...modified
            }
          : {
              id,
              ...modified
            }

        // limit to keep local storage size in check
        const merged = take(
          uniqBy([update, ...prev], it => it.id),
          50
        )

        return merged
      })
    },
    [setActivity]
  )

  return {
    ...state,
    open,
    close,
    activeChats,
    setActiveChats,
    handleActivate,
    handleShowChat,
    handleCloseChat,
    activity,
    getChatActivity,
    updateChatActivity
  }
}

// Exported for Storybook Mocking only
export type ChatType = ReturnType<typeof useChatApi>
export const ChatContext = createContext(Initial as ChatType)
export const ChatProvider = ({
  children,
  ...props
}: {
  children?: React.ReactNode
}) => {
  const value = useChatApi()
  return (
    <ChatContext.Provider value={value} {...props}>
      {children}
    </ChatContext.Provider>
  )
}
