import Box from '@mui/material/Box'

import { RcButton } from '@/components/molecules/interactive/RcButton'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTrans } from '@/components/atoms/RcTrans'

export const Complete: React.FC<{
  onComplete: () => void
}> = ({ onComplete }) => {
  return (
    <ModalConfiguration
      title={<RcTrans i18nKey='shared.youre-all-done' />}
      subtitle={
        <Box display='flex' justifyContent='center' alignItems='center'>
          <RcTrans i18nKey='contact:general.thank-you' />
        </Box>
      }
    >
      <RcButton fullWidth onClick={onComplete} variant='contained'>
        <RcTrans i18nKey='shared.continue' />
      </RcButton>
    </ModalConfiguration>
  )
}
