import Portal from '@mui/material/Portal'
import { IconProps } from '@mui/material'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useModal } from './ModalProvider'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ConfirmingButton,
  ConfirmingButtonProps
} from '@/components/molecules/interactive/ConfirmingButton'

export interface ModalTopActionProps {
  topAction?: () => any
  topActionProps?: ConfirmingButtonProps
}

export const ModalTopAction: React.FC<ModalTopActionProps> = ({
  topAction,
  topActionProps
}) => {
  const { topActionContainer, isInModalContext } = useModal()
  if (!topAction || !isInModalContext || topActionContainer.current === null)
    return null
  return (
    <Portal container={topActionContainer?.current}>
      {topActionProps ? (
        <ConfirmingButton onClick={topAction} {...topActionProps} />
      ) : (
        <RcIconButton icon={['fal', 'chevron-left']} onClick={topAction} />
      )}
    </Portal>
  )
}
