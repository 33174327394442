import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import { ContactFlowStep } from './_ContactFlow'
import { usePage } from '@/components/providers/site/PageProvider'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcPaperButton } from '@/components/molecules/interactive/RcPaperButton'
import { DiscordTransLink } from '@/components/organisms/site/DiscordTransLink'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { TitleParagraph } from '@/components/molecules/text/TitleParagraph'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useUserAccount } from '@/entity/user/useUserAccount'

export const SelectContact: React.FC<{
  onSelected: (step: ContactFlowStep) => void
}> = ({ onSelected }) => {
  const { user } = useUserAccount()
  const { contact } = usePage()

  const handleSelection = (value: ContactFlowStep) => {
    onSelected(value)
  }

  return (
    <Grid container direction='column' wrap='nowrap' spacing={5}>
      {user && contact ? (
        <Grid item>
          <RcPaperButton onClick={() => handleSelection('ticket')}>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <RcIcon mb={3} icon={['fal', 'comment-lines']} size='lg' />

              <TitleParagraph
                align='center'
                title={contact.title}
                paragraph={contact.subtitle}
              />
            </Box>
          </RcPaperButton>
        </Grid>
      ) : null}

      <Grid item>
        <RcPaperButton onClick={() => handleSelection('other')}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <RcIcon mb={3} icon={['fal', 'comment-lines']} size='lg' />

            <TitleParagraph
              align='center'
              title={<RcTrans i18nKey='contact:other.label' />}
              paragraph={<RcTrans i18nKey='contact:other.label-help' />}
            />
          </Box>
        </RcPaperButton>
      </Grid>

      <Grid item>
        <RcPaperButton onClick={() => handleSelection('report')}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <RcIcon mb={3} icon={['fal', 'ban']} size='lg' />
            <TitleParagraph
              align='center'
              title={<RcTrans i18nKey='shared.report-player' />}
              paragraph={<RcTrans i18nKey='contact:report.label-help' />}
            />
          </Box>
        </RcPaperButton>
      </Grid>
      <Grid item>
        <RcPaperButton onClick={() => handleSelection('selectFeedback')}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <RcIcon mb={3} icon={['fal', 'download']} size='lg' />

            <TitleParagraph
              align='center'
              title={<RcTrans i18nKey='contact:give-feedback.label' />}
              paragraph={<RcTrans i18nKey='contact:give-feedback.help' />}
            />
          </Box>
        </RcPaperButton>
      </Grid>
      <Grid item />
      <ModalConfiguration
        title={<RcTrans i18nKey='contact:contact.label' />}
        subtitle={
          <>
            <RcTrans i18nKey='contact:menu.help' />{' '}
            <DiscordTransLink override={contact?.discord} />
          </>
        }
      />
    </Grid>
  )
}
