import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { AvatarProps } from '@mui/material/Avatar'
import { styled } from '@mui/material/styles'
import React, { Children, PropsWithChildren } from 'react'
import { number } from 'yup'
import { RcAvatar } from './RcAvatar'
import { RcIcon } from '@/components/atoms/RcIcon'

const OnlineBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    minWidth: 12,
    minHeight: 12,
    borderRadius: 12,
    padding: 2,
    aspectRatio: 1
  }
}))

export interface AvatarTextProps extends AvatarProps {
  isParty?: boolean
  isInterest?: boolean
  isOnline?: boolean
  isLive?: boolean
  isDegraded?: boolean
  badgeContent?: React.ReactNode
  badgeIcon?: IconProp
  badgeTooltip?: React.ReactNode
  avatarChildren?: React.ReactNode
  rightAlign?: boolean
  skipAvatar?: boolean
  contain?: boolean
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl'
}

export const sizeSx = {
  xs: { width: 20, height: 20, borderRadius: 0.75 },
  small: { width: 32, height: 32 },
  medium: { width: 40, height: 40 },
  large: { width: 50, height: 50 },
  xl: { width: 70, height: 70 },
  xxl: { width: 160, height: 160 }
}

/**
  Avatar with text display and online indicator.
 */
export const AvatarText = ({
  isOnline,
  isLive,
  isDegraded,
  badgeContent,
  badgeIcon,
  badgeTooltip,
  avatarChildren,
  onClick,
  rightAlign,
  contain,
  size,
  children,
  src,
  alt,
  ...props
}: PropsWithChildren<AvatarTextProps>) => {
  const baseSx = sizeSx[size || 'medium'] as {
    width: number
    height: number
    borderRadius: number
  }

  if (props.variant === 'circular') {
    baseSx.borderRadius = 10
  }

  const baseAvatar = (
    <RcAvatar {...props} src={src} alt={alt} size={size}>
      {avatarChildren}
    </RcAvatar>
  )
  const wrapped =
    isOnline || isLive || badgeContent || badgeIcon ? (
      <OnlineBadge
        badgeContent={
          badgeContent ? (
            badgeContent
          ) : badgeIcon ? (
            <Tooltip
              title={badgeTooltip || ''}
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -10]
                    }
                  }
                ]
              }}
            >
              <Box>
                <RcIcon icon={badgeIcon} size='sm' />
              </Box>
            </Tooltip>
          ) : (
            ''
          )
        }
        variant={badgeIcon || badgeContent ? 'standard' : 'dot'}
        color={
          isDegraded
            ? 'warning'
            : isLive
            ? 'error'
            : isOnline
            ? 'success'
            : 'secondary'
        }
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {baseAvatar}
      </OnlineBadge>
    ) : (
      baseAvatar
    )

  if (!children)
    return (
      <Stack onClick={onClick} sx={{ cursor: 'pointer' }}>
        {wrapped}
      </Stack>
    )

  return (
    <Stack
      direction={rightAlign ? 'row-reverse' : 'row'}
      spacing={size === 'xs' ? 2 : 3}
      alignItems='center'
      onClick={onClick}
      sx={{ cursor: onClick ? 'pointer' : 'inherit', flexGrow: 1 }}
    >
      {props.skipAvatar ? null : <Box sx={{ mt: 1 }}>{wrapped}</Box>}
      <Stack
        direction='column'
        spacing={1}
        alignSelf='center'
        alignItems={rightAlign ? 'flex-end' : 'flex-start'}
        width={'100%'}
      >
        {Children.toArray(children).map((it, idx) => (
          <Box
            key={idx}
            width={contain ? `calc(100% - ${baseSx.width / 2}px)` : undefined}
          >
            {it}
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}
