'use client'

import Skeleton from '@mui/material/Skeleton'

export const RcLoader = ({
  height,
  bgcolor,
  borderRadius = 1
}: {
  height?: number | string
  bgcolor?: string
  borderRadius?: number | string
}) => {
  return (
    <Skeleton
      variant='rectangular'
      sx={theme => ({
        bgcolor,
        flexGrow: 1,
        borderRadius,
        width: '100%',
        minHeight: height || `calc(100dvh  - ${theme.spacing(12)})`
      })}
    />
  )
}
