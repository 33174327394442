'use client'

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

/**
 * Manages RcModal images, text, and formatting options.
 */
export const useModal = () => useContext(ModalContext)

const useModalApi = () => {
  const [hydrated, setHydrated] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [maxWidth, setMaxWidth] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl'>()

  const avatarContainer = useRef<any>(null)
  const titleContainer = useRef<any>(null)
  const subtitleContainer = useRef<any>(null)
  const topActionContainer = useRef<any>(null)
  const topContentContainer = useRef<any>(null)
  const actionsContainer = useRef<any>(null)

  useEffect(() => setHydrated(true), [])

  return {
    formDirty,
    setFormDirty,
    avatarContainer,
    titleContainer,
    subtitleContainer,
    topActionContainer,
    topContentContainer,
    actionsContainer,
    isInModalContext: hydrated,
    maxWidth,
    setMaxWidth
  }
}

// Exported for Storybook Mocking only
export type ModalType = ReturnType<typeof useModalApi>
const ModalContext = createContext({} as ModalType)
export const ModalProvider = (props: { children?: React.ReactNode }) => {
  const api = useModalApi()
  return (
    <ModalContext.Provider value={api}>{props.children}</ModalContext.Provider>
  )
}
