import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { RcIcon, RcIconProps } from '@/components/atoms/RcIcon'

export interface TitleParagraphProps {
  RcIconProps?: RcIconProps
  align?: any
  title: React.ReactNode
  paragraph?: React.ReactNode
}

export const TitleParagraph: React.FC<TitleParagraphProps> = ({
  RcIconProps,
  align,
  title,
  paragraph
}) => {
  return (
    <Stack direction='column' spacing={3}>
      {!!RcIconProps ? <RcIcon {...RcIconProps} /> : null}
      <Typography variant='body0' align={align} color='text.primary'>
        {!!RcIconProps ? (
          <Box width='32px' display='inline' mr={2}>
            <RcIcon {...RcIconProps} />
          </Box>
        ) : null}
        {title}
      </Typography>
      {paragraph && (
        <Typography variant='body1' align={align} color='text.primary'>
          {paragraph}
        </Typography>
      )}
    </Stack>
  )
}
